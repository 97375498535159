import {
  IconDefinition,
  faBackpack,
  faBallotCheck,
  faCalendarAlt,
  faChalkboardTeacher,
  faClipboardUser,
  faCommentAltDots,
  faGraduationCap,
  faPollH,
  faSlidersVSquare,
  faTachometerAltFast,
  faUniversity,
  faUsdCircle,
  faUser,
  faUserGraduate,
} from "@fortawesome/pro-light-svg-icons";
import { USER_TYPE, limit } from "src/constant/index";

import { SizeProp } from "@fortawesome/fontawesome-svg-core";

type subAuth = "ACCOUNT" | "ADMIN" | "TEACHING";

export type BaseSidebarManifest = {
  id: string;
  label: string;
  url?: string;
  module: string;
  auth: number[];
  subAuth?: subAuth[];
  icon: IconDefinition | string;
  faIconSize?: SizeProp;
  children?: ChildSidebarManifest[];
};

type OmitIconAndSize = Omit<BaseSidebarManifest, "icon" | "faIconSize">;

export type ChildSidebarManifest = OmitIconAndSize & {
  url: string;
};

export const defaultPagingURLSegment: string = `limit/${limit}/page/1`;

export const sidebarManifest: BaseSidebarManifest[] = [
  {
    id: "dashboard",
    label: "dashboard.label",
    url: "/dashboard",
    icon: faTachometerAltFast,
    faIconSize: "lg",
    module: "default",
    auth: [
      USER_TYPE.EDBA_ADMIN,
      USER_TYPE.EMPLOYEE,
      USER_TYPE.STUDENT,
      USER_TYPE.APPLICANT,
    ],
  },
  {
    id: "institutes",
    label: "institutes.label",
    url: `/institutes/${defaultPagingURLSegment}`,
    icon: faUniversity,
    faIconSize: "lg",
    module: "default",
    auth: [USER_TYPE.EDBA_ADMIN],
  },
  {
    id: "accounts",
    label: "accounts.label",
    icon: faUsdCircle,
    faIconSize: "lg",
    module: "default",
    auth: [USER_TYPE.EDBA_ADMIN],
    children: [
      {
        id: "accounts_overview",
        label: "accounts.label",
        url: "/accounts",
        module: "default",
        auth: [USER_TYPE.EDBA_ADMIN],
      },
      {
        id: "accounts_ndps",
        label: "ndpsMerchants.label",
        url: `/ndps/merchants/${defaultPagingURLSegment}`,
        module: "default",
        auth: [USER_TYPE.EDBA_ADMIN],
      },
    ],
  },
  {
    id: "academics",
    label: "academics.label",
    icon: faGraduationCap,
    faIconSize: "lg",
    module: "Academics",
    auth: [USER_TYPE.EDBA_ADMIN, USER_TYPE.EMPLOYEE],
    subAuth: ["ADMIN"],
    children: [
      {
        id: "academics_boardUniversities",
        label: "boardUniversities.label",
        url: `/academics/boards-universities/${defaultPagingURLSegment}`,
        module: "Courses",
        auth: [USER_TYPE.EDBA_ADMIN],
      },
      {
        id: "academics_streams",
        label: "streams.label",
        url: `/academics/streams/${defaultPagingURLSegment}`,
        module: "Streams",
        auth: [USER_TYPE.EDBA_ADMIN],
      },
      {
        id: "academics_courses",
        label: "courses.label",
        url: `/academics/courses/${defaultPagingURLSegment}`,
        module: "Courses",
        auth: [USER_TYPE.EDBA_ADMIN, USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
      {
        id: "academics_classes",
        label: "classes.label",
        url: `/academics/classes/${defaultPagingURLSegment}`,
        module: "Classes",
        auth: [USER_TYPE.EDBA_ADMIN, USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
      {
        id: "academics_subjects",
        label: "subjects.label",
        url: `/academics/subjects/${defaultPagingURLSegment}`,
        module: "Subjects",
        auth: [USER_TYPE.EDBA_ADMIN, USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
      {
        id: "academics_subjectGroups",
        label: "subjectGroups.label",
        url: `/academics/subject-groups/${defaultPagingURLSegment}`,
        module: "SubjectGroup",
        auth: [USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
      {
        id: "academics_years",
        label: "years.label",
        url: `/academics/years/${defaultPagingURLSegment}`,
        module: "AcademicYear",
        auth: [USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
    ],
  },
  {
    id: "communication",
    label: "communication.label",
    icon: faCommentAltDots,
    faIconSize: "lg",
    module: "default",
    auth: [USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT],
    children: [
      {
        id: "communication_newsNotices",
        label: "newsNotices.label",
        url: "/communication",
        module: "default",
        auth: [USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT],
      },
      {
        id: "communication_events",
        label: "events.label",
        url: "/events",
        module: "default",
        auth: [USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT],
      },
      {
        id: "communication_message",
        label: "message.label",
        url: "/messages",
        module: "default",
        auth: [USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT],
      },
    ],
  },
  {
    id: "timetable",
    label: "timetable.label",
    url: "/time-table",
    icon: faCalendarAlt,
    faIconSize: "lg",
    module: "default",
    auth: [USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT],
    subAuth: ["TEACHING"],
  },
  {
    id: "assignments",
    label: "assignments.label",
    url: "/assignments",
    icon: faBackpack,
    faIconSize: "lg",
    module: "default",
    auth: [USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT],
    subAuth: ["TEACHING"],
  },
  {
    id: "attendance_employee",
    label: "attendance.label",
    url: "/attendance/mark/student",
    icon: faClipboardUser,
    faIconSize: "lg",
    module: "default",
    auth: [USER_TYPE.EMPLOYEE],
    subAuth: ["TEACHING"],
  },
  {
    id: "attendance_student",
    label: "attendance.label",
    url: "/attendance/history",
    icon: faClipboardUser,
    faIconSize: "lg",
    module: "default",
    auth: [USER_TYPE.STUDENT],
  },
  {
    id: "payment_employee",
    label: "payment.label",
    icon: faUsdCircle,
    faIconSize: "lg",
    module: "Payments",
    auth: [USER_TYPE.EMPLOYEE],
    subAuth: ["ACCOUNT"],
    children: [
      {
        id: "payment_employee_overview",
        label: "overview.label",
        url: `/payments/overview/${defaultPagingURLSegment}`,
        module: "Payments",
        auth: [USER_TYPE.EMPLOYEE],
        subAuth: ["ACCOUNT"],
      },
      {
        id: "payment_employee_accountDetails",
        label: "accountDetails.label",
        url: `/payments/account_details/${defaultPagingURLSegment}`,
        module: "Payments",
        auth: [USER_TYPE.EMPLOYEE],
        subAuth: ["ACCOUNT"],
      },
    ],
  },
  {
    id: "payment_student",
    label: "payment.label",
    url: "/payments/pending",
    icon: faUsdCircle,
    faIconSize: "lg",
    module: "Payments",
    auth: [USER_TYPE.STUDENT],
  },
  {
    id: "classrooms",
    label: "classrooms.label",
    url: "/classrooms",
    icon: faUserGraduate,
    faIconSize: "lg",
    module: "Courses",
    auth: [USER_TYPE.EMPLOYEE],
    subAuth: ["ADMIN"],
  },
  {
    id: "students_overview",
    label: "students.label",
    url: `/students/${defaultPagingURLSegment}`,
    icon: faUser,
    faIconSize: "lg",
    module: "default",
    auth: [USER_TYPE.EMPLOYEE],
    subAuth: ["ADMIN"],
  },
  {
    id: "employees_overview",
    label: "employee.label",
    url: `/employees/${defaultPagingURLSegment}`,
    icon: faChalkboardTeacher,
    faIconSize: "lg",
    module: "Employee",
    auth: [USER_TYPE.EMPLOYEE],
    subAuth: ["ADMIN"],
  },
  {
    id: "admissions_employee",
    label: "admissions.label",
    url: `/admissions/${defaultPagingURLSegment}`,
    icon: "admission",
    module: "Admissions",
    auth: [USER_TYPE.EMPLOYEE],
    subAuth: ["ADMIN"],
  },
  {
    id: "assessment",
    label: "assessment.label",
    icon: faBallotCheck,
    faIconSize: "lg",
    module: "Assesment",
    auth: [USER_TYPE.EDBA_ADMIN, USER_TYPE.EMPLOYEE],
    subAuth: ["ADMIN"],
    children: [
      {
        id: "assessment_grading_systems",
        label: "gradingSystems.label",
        url: `/assessment/grading-systems/${defaultPagingURLSegment}`,
        module: "default",
        auth: [USER_TYPE.EDBA_ADMIN],
      },
      {
        id: "assessment_tests",
        label: "tests.label",
        url: `/assessment/tests/${defaultPagingURLSegment}`,
        module: "Assesment",
        auth: [USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
      {
        id: "assessment_credits",
        label: "credits.label",
        url: `/assessment/credits`,
        module: "Assesment",
        auth: [USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
      {
        id: "assessment_exams",
        label: "exams.label",
        url: `/assessment/exams/${defaultPagingURLSegment}`,
        module: "Assesment",
        auth: [USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
      {
        id: "assessment_marks",
        label: "marks.label",
        url: `/assessment/marks/limit/100/page/1`,
        module: "Assesment",
        auth: [USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
      {
        id: "assessment_results",
        label: "results.label",
        url: `/assessment/results/${defaultPagingURLSegment}`,
        module: "Assesment",
        auth: [USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
    ],
  },
  {
    id: "applications",
    label: "applications.label",
    url: `/application/list/${defaultPagingURLSegment}`,
    faIconSize: "lg",
    icon: faBallotCheck,
    module: "default",
    auth: [USER_TYPE.STUDENT],
  },
  {
    id: "admission_edba_admin",
    label: "admission.label",
    icon: "admission",
    module: "default",
    auth: [USER_TYPE.EDBA_ADMIN],
    children: [
      {
        id: "admission_edba_admin_casteCategories",
        label: "casteCategories.label",
        url: `/admission/caste-categories/${defaultPagingURLSegment}`,
        module: "default",
        auth: [USER_TYPE.EDBA_ADMIN],
      },
      {
        id: "admission_edba_admin_reservationGroups",
        label: "reservationGroups.label",
        url: `/admission/reservation-groups/${defaultPagingURLSegment}`,
        module: "default",
        auth: [USER_TYPE.EDBA_ADMIN],
      },
      {
        id: "admission_edba_admin_documents",
        label: "documents.label",
        url: `/admission/documents/${defaultPagingURLSegment}`,
        module: "default",
        auth: [USER_TYPE.EDBA_ADMIN],
      },
      {
        id: "admission_edba_admin_qualifications",
        label: "qualifications.label",
        url: `/admission/qualifications/${defaultPagingURLSegment}`,
        module: "default",
        auth: [USER_TYPE.EDBA_ADMIN],
      },
      {
        id: "admission_edba_admin_criteria",
        label: "criteria.label",
        url: `/admission/criteria/${defaultPagingURLSegment}`,
        module: "default",
        auth: [USER_TYPE.EDBA_ADMIN],
      },
    ],
  },
  {
    id: "controlPanel",
    label: "controlPanel.label",
    icon: faSlidersVSquare,
    faIconSize: "lg",
    module: "default",
    auth: [USER_TYPE.EDBA_ADMIN, USER_TYPE.EMPLOYEE],
    subAuth: ["ADMIN"],
    children: [
      {
        id: "controlPanel_translations",
        label: "translations.label",
        url: `/control-panel/translations/${defaultPagingURLSegment}`,
        module: "Language",
        auth: [USER_TYPE.EDBA_ADMIN, USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
      {
        id: "controlPanel_payment",
        label: "payment.label",
        url: "/control-panel/payments",
        module: "Payments",
        auth: [USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
      {
        id: "controlPanel_otherPayment",
        label: "otherPayment.label",
        url: `/control-panel/other-payment/${defaultPagingURLSegment}`,
        module: "Payments",
        auth: [USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
      {
        id: "controlPanel_concession",
        label: "concession.label",
        url: `/payments/concession/${defaultPagingURLSegment}`,
        module: "Payments",
        auth: [USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
      {
        id: "controlPanel_user",
        label: "user.label",
        url: `/control-panel/users/${defaultPagingURLSegment}`,
        module: "default",
        auth: [USER_TYPE.EDBA_ADMIN, USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
      {
        id: "controlPanel_settings",
        label: "settings.label",
        url: `/control-panel/settings`,
        module: "Student",
        auth: [USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
      {
        id: "controlPanel_atktRevalsettings",
        label: "atktReval.label",
        url: `/control-panel/ATKTRevalsettings`,
        module: "Student",
        auth: [USER_TYPE.EMPLOYEE],
        subAuth: ["ADMIN"],
      },
    ],
  },
  {
    id: "logs",
    label: "logs.label",
    url: `/logs/${defaultPagingURLSegment}`,
    faIconSize: "lg",
    icon: faBallotCheck,
    module: "default",
    auth: [USER_TYPE.EDBA_ADMIN],
  },
  {
    id: "results",
    label: "results.label",
    url: `/results/${defaultPagingURLSegment}`,
    faIconSize: "lg",
    icon: faPollH,
    module: "default",
    auth: [USER_TYPE.STUDENT],
  },
];
